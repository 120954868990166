import { createGlobalStyle } from 'styled-components';

const theme = {
  layout: {
    sm: 576,
    md: 768,
    lg: 1080,
    containerWidth: {
      md: 720,
      lg: 1000,
    },
  },
  color: {
    text: {
      primary: 'rgba(255,255,255,1)',
      secondary: 'rgba(255,255,255,0.6)',
      secondaryLessTransparent: 'rgba(255,255,255,0.8)',
      accent: 'rgba(247,65,87,1)',
    },
    background: {
      top: 'rgba(14,0,39,1)',
      bottom: 'rgba(20,18,18,1)',
      transparent: 'rgba(255,255,255,0)',
      color: 'linear-gradient(0deg, #141212 0%, #0e0027 100%) !important',
    },
  },
};

interface GlobalStyleProp {
  bgColor?: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProp>`
  html {
    margin: 0;
    min-height: 100vh;
  }
  #___gatsby {
    margin: 0;
    padding: 30px 0;

    font-family: 'Work Sans', 'Roboto Mono', monospace, sans-serif;

    ${({ bgColor }) => {
      return {
        'background-size': 'auto 100%',
        'background-repeat': 'no-repeat',
        'background-position': 'left top',
        background: bgColor?.length ? bgColor : theme.color.background.color,
      };
    }}
  }
`;

export default theme;
